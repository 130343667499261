body {
  margin: 0px;
  height: 100%;
  width: 100%;
  background: radial-gradient(#264a50, #040d13);
  min-height: 6666px;
  overflow-x: hidden;
  overflow-y: hidden;
}

* {
  -webkit-text-stroke: 0.5px #040d13;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.Main {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-image: url("../components/UI/image/background.png");
  border-image: url("../components/UI/image/borderthin.png") 45%;
  border-image-width: 15px;
  border-image-outset: 5px;
  width: 500px;
  height: 500px;
  left: 0;
  right: 0;
  margin: 0 auto;
  margin-top: 75px;
}

.leftContainer {
  background-color: bisque;
  width: 220px;
  height: 430px;
  top: 50px;
  left: 0px;

  border: 5px solid transparent;
  border-image: url("../components/UI/image/border.png") 45%;
  border-image-width: 15px;
  border-image-outset: 2px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.rightContainer {
  background-color: bisque;
  width: 220px;
  height: 430px;
  border: 5px solid transparent;
  border-image: url("../components/UI/image/border.png") 45%;
  border-image-width: 15px;
  border-image-outset: 2px;
  overflow-x: hidden;
}

.leftWindow button {
  display: grid;
  grid-template-columns: repeat(1, 108px);
  margin: auto;
  position: bottom;
  gap: 20px;
  padding: 5px;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 100%;
}

.leftWindow {
  border: 5px solid transparent;
  border-image: url("../components/UI/image/border.png") 45%;
  border-image-width: 15px;
  border-image-outset: 2px;
  background-image: url("../components/UI/image/background2.png");
}

.rightWindow {
  border: 5px solid transparent;
  border-image: url("../components/UI/image/border.png") 45%;
  border-image-width: 15px;
  border-image-outset: 2px;
  background-image: url("../components/UI/image/background2.png");
}

.versionBlock {
  background-image: url("../components/UI/image/background.png");
  border-image: url("../components/UI/image/borderthin.png") 45%;
  border-image-width: 15px;
  border-image-outset: 5px;
  width: 50px;
  height: 30px;
  margin-left: 10px;
  margin-top: 30px;
  padding-left: 5px;
  font-size: 90%;
  position: absolute;
  top: 0px;
}
h2 {
  margin: 0; /* Убирает отступы */
  padding: 0; /* Убирает внутренние отступы */
  text-align: center; /* Если нужно выравнивание по центру */
  color: rgb(231, 231, 231);
}
h1 {
  margin: 0; /* Убирает отступы */
  padding: 0; /* Убирает внутренние отступы */
  text-align: center; /* Если нужно выравнивание по центру */
  color: rgb(231, 231, 231);
}

.leftBottomWindow {
  border: 5px solid transparent;
  border-image: url("../components/UI/image/border.png") 45%;
  border-image-width: 15px;
  border-image-outset: 2px;
  background-image: url("../components/UI/image/background2.png");
  height: 50%;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  -webkit-text-stroke: 0.5px #040d13;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.rightBottomWindow {
  border: 5px solid transparent;
  border-image: url("../components/UI/image/border.png") 45%;
  border-image-width: 15px;
  border-image-outset: 2px;
  background-image: url("../components/UI/image/background2.png");
  height: 50%;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  -webkit-text-stroke: 0.5px #040d13;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.rightBottomWindow img {
  height: 100%;
  width: 100%;
}

.leftBottomWindow img {
  height: 100%;
  width: 100%;
}

.lifeweave img {
  height: 100%;
  width: 100%;
}
