.rightWindow {
  color: #f0f0f0;
  font-family: "Montserrat", sans-serif;
}

.rightWindow button {
  display: grid;
  grid-template-columns: repeat(1, 108px);
  margin: auto;
  gap: 20px;
  padding: 5px;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 100%;
  color: aliceblue;
}
